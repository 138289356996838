import React, { Fragment, useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Paper, Snackbar, TextField, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from "prop-types";
import SubmissionDetailTable from "./SubmissionDetailTable";
import DNSubmissionForm from './DNSubmissionForm';
import DCISubmissionForm from './DCISubmissionForm';
import SubmissionAttachmentDialog from './SubmissionAttachmentDialog';
import { useConfirm } from 'material-ui-confirm';

const makeDialogStyles = makeStyles(() => ({
    loading: {
        top: '40%',
        left: '40%',
        position: 'fixed',
        display: 'flex',
        flexFlow: 'column',
        zIndex: '99',
        textAlign: 'center',
    },
    tablePaper: {
        marginBottom: ' 7px',
    },
    dialogStyle: {
        height: '95%',
    },
    dialogTitle: {
        padding: '5px 5px',
        backgroundColor: '#cfcfcf',
    },
    dialogActions: {
        padding: '5px 5px',
        backgroundColor: '#cfcfcf',
        justifyContent: 'space-evenly',
    },
    dialogContentStyle: {
        height: 'auto',
        width: 'auto',
        padding: '8px 8px',
    },
    formPaper: {
        padding: ' 5px',
    },
    horizontalSpacer: {
        width: '100%',
    },
}));

const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SubmissionFormDialog = props => {
    const {
        open,
        saving,
        submission,
        pickLists,
        onUpdateSubmission,
        onSubmissionChanged,
        onClose,
        onCancelReq,
        onSubmitReq,
        onSaveRequest,
        onRemoveAttachment,
    } = props;

    const [selectedDetail, setSelectedDetail] = useState(null);
    const [showAttachments, setShowAttachments] = useState(false);
    const [newDetailId, setNewDetailId] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);

    // editable fields...
    const [filename, setFilename] = useState('');
    const [issuer, setIssuer] = useState({});
    const [artType, setArtType] = useState('');
    const [franchise, setFranchise] = useState({});
    const [mfrJobNum, setMfrJobNum] = useState('');
    const [qty, setQty] = useState('');
    const [cardType, setCardType] = useState('');
    const [dtlPrelaunchRecapFlag, setDtlPrelaunchRecapFlag] = useState('');
    const [dtlDescription, setDtlDescription] = useState('');
    const [dtlAttachments, setDtlAttachments] = useState([]);

    const classes = makeDialogStyles();
    const confirm = useConfirm();

    useEffect(() => {
        if (submission && submission.submissionDetails && Array.isArray(submission.submissionDetails) && submission.submissionDetails.length > 0) {
            if (selectedDetail != null) {
                var dtl = submission.submissionDetails.find(dtl => dtl.issuerArtDtlId == selectedDetail.issuerArtDtlId);
                setSelectedDetail(dtl);
            } else {
                setSelectedDetail(submission.submissionDetails[submission.submissionDetails.length-1]);
            }
            if (newDetailId == 0)
                setNewDetailId(submission.submissionDetails.length * -1);
        }
        setFilename(submission.filename);
        setIssuer({
            issuerId: submission.issuerId, 
            issuerName: submission.issuerName,
            submissionTarget: submission.submissionTarget,
        });
        setArtType(submission.artType);
        setFranchise({
            franchiseId: submission.franchiseId, 
            franchiseName: submission.franchiseName,
        });
        setMfrJobNum(submission.mfrJobNum);
        setQty(submission.qty);
        setCardType(submission.cardType);
    }, [submission])
    
    useEffect(() => {
        if (selectedDetail != null) {
            setDtlDescription(selectedDetail.description);
            setDtlPrelaunchRecapFlag(selectedDetail.prelaunchRecapFlag);
            setDtlAttachments(selectedDetail.detailAttachments);
        } else {
            setDtlDescription('');
            setDtlPrelaunchRecapFlag('');
            setDtlAttachments([]);
        }
    }, [selectedDetail]);


    const onRowSelect = selectedDetailRow => {
        setSelectedDetail(selectedDetailRow);
    }

    const updateDetail = (update, saveOnChange) => {
        var updatedDetail = Object.assign(selectedDetail, update);
        setSelectedDetail(updatedDetail);
        var details = submission.submissionDetails;
        var dtlIndex = details.findIndex(dtl => dtl.issuerArtDtlId == updatedDetail.issuerArtDtlId);
        details[dtlIndex] = updatedDetail;
        handleFormChange({target: {
            name: "submissionDetails", 
            value: details, 
            saveOnChange: saveOnChange,
        }});
    }

    const handleFormChange = event => {
        var updateObject = {};
        switch(event.target.name) {
            // Handle submission changes...
            case "filename":
            case "artType":
            case "submissionDetails":
            case "franchiseId":
            case "franchiseName":
            case "mfrJobNum":
            case "qty":
            case "cardType":
                updateObject[event.target.name] = event.target.value;
                updateObject.isDirty = true;
                onUpdateSubmission(updateObject, false);
                break;
            case "prelaunchRecapFlag":
            case "description":
            case "detailAttachments":
                updateObject[event.target.name] = event.target.value;
                updateDetail(updateObject, false);
                break;
        }
    }

    const onConfirmCancelReq = () => {
        confirm({ 
            title: "Cancel Request", 
            description: `Are you sure that you want to cancel this request?`,
            confirmationText: "Yes",
            cancellationText: "No",
        })
        .then(() => onCancelReq())
        .catch(() => console.log("Cancel Submission aborted."));
    }

    const onConfirmSubmitReq = () => {
        var errors = "";
        var errorSep = "";

        if (!submission.filename) {
            errors += errorSep + "A Filename must be provided";
            errorSep = "; ";
        }
        if (!submission.issuerId) {
            errors += errorSep + "A Client must be selected";
            errorSep = "; ";
        }

        if (!submission.submissionTarget) {
            errors += errorSep + "You must select a submission target";
            errorSep = "; ";
        } else if (submission.submissionTarget == "DN") {
            if (!submission.artType) {
                errors += errorSep + "Art Type must be selected";
                errorSep = "; ";
            }
        } else if (submission.submissionTarget == "DCI") {
            if (!submission.franchiseId || submission.franchiseId <= 0) {
                errors += errorSep + "A Franchise must be selected";
                errorSep = "; ";
            }
            if (!submission.mfrJobNumb || submission.mfrJobNumb == '') {
                errors += errorSep + "A Manufacturer Job # must be provided";
                errorSep = "; ";
            }
            if (!submission.qty || submission.qty == '') {
                errors += errorSep + "A Quantity must be provided";
                errorSep = "; ";
            }
            if (!submission.cardType || submission.cardType == '') {
                errors += errorSep + "A Card Type must be selected";
                errorSep = "; ";
            }
        } else {
            errors += errorSep + "You must select a submission target";
            errorSep = "; ";
        }

        if (!dtlAttachments || dtlAttachments.length == 0) {
            errors += errorSep + "An attachment must be included";
            errorSep = "; ";
        }

        if (errors) {
            setAlertMessage(`This request cannot be submitted: ${errors}`);
            setShowAlert(true);
            return;
        }

        confirm({ 
            title: "Submit Request", 
            description: `Are you sure that you want to submit this request?`,
            confirmationText: "Yes",
            cancellationText: "No",
        })
        .then(() => onSubmitReq(selectedDetail))
        .catch(() => console.log("Cancel Submission aborted."));
    }








    // Editable field changehandlers

    const handleSubmissionChange = changedSubmission => {
        changedSubmission.isDirty = true;
        onSubmissionChanged(changedSubmission);
    }

    const handleClientChange = (event, value) => {
        var newIssuer = {
            issuerId : value.id,
            issuerName : value.issuerName,
            submissionTarget : value.submitNetworkInd? "DN": "DCI",
        }
        setIssuer(newIssuer);
        submission.issuerId = value.id;
        submission.issuerName = value.issuerName;
        submission.submissionTarget = value.submitNetworkInd? "DN": "DCI";
        handleSubmissionChange(submission);
    }

    const handleFilenameChange = event => {
        setFilename(event.target.value);
        submission.filename = event.target.value;
        handleSubmissionChange(submission);
    }

    const handleArtTypeChange = event => {
        setArtType(event.target.value);
        submission.artType = event.target.value;
        handleSubmissionChange(submission);
    }

    const handleFranchiseChange = (event, value) => {
        var newFranchise = {
            franchiseId : value.id,
            franchiseName : value.franchiseName,
        }
        setFranchise(newFranchise);
        submission.franchiseId = value.id;
        submission.franchiseName = value.franchiseName;
        handleSubmissionChange(submission);
    }

    const handleMfrJobNumChange = event => {
        setMfrJobNum(event.target.value);
        submission.mfrJobNum = event.target.value;
        handleSubmissionChange(submission);
    }

    const handleQtyChange = event => {
        setQty(event.target.value);
        submission.qty = event.target.value;
        handleSubmissionChange(submission);
    }

    const handleCardTypeChange = event => {
        setCardType(event.target.value);
        submission.cardType = event.target.value;
        handleSubmissionChange(submission);
    }

    const handleDtlPrelaunchRecapFlagChange = event => {
        setDtlPrelaunchRecapFlag(event.target.value);
        selectedDetail.prelaunchRecapFlag = event.target.value;
        handleDetailChange(selectedDetail);
    }

    const handleDtlDescriptionChange = event => {
        setDtlDescription(event.target.value);
        selectedDetail.description = event.target.value;
        handleDetailChange(selectedDetail);
    }

    const handleDetailChange = changedDetail => {
        var detailList = submission.submissionDetails;
        if (!detailList || !Array.isArray(detailList)) {
            detailList = [];
        }
        var dtlIx = detailList.findIndex(item => item.issuerArtDtlId == changedDetail.issuerArtDtlId);
        if (dtlIx >= 0) {
            detailList[dtlIx] = changedDetail;
        } else {
            detailList.push(changedDetail);
        }
        submission.submissionDetails = detailList;
        handleSubmissionChange(submission);
    }

    const handleDtlAttachmentsChanged = attachments => {
        selectedDetail.detailAttachments = attachments;
        handleDetailChange(selectedDetail);
    }






    // Autocomplete stuff
    const isClientSelected = (option, value) => {
        return option.id == value.issuerId;
    }
    const renderClientSelectInput = (params) => {
        return (
            <TextField {...params} label="Client" variant="outlined" />
        )
    }
    const findClientLabel = option => {
        return (option? option.issuerName: "No Option Available");
    }

    const onAddVersion = () => {
        var newDtlid = newDetailId;
        setNewDetailId(newDetailId - 1);
        var newDetail = {
            issuerArtDtlId : newDtlid,
            issuerArtId : submission.issuerArtId,
            status : 'N',
            version : (submission.currentVersion + 1),
            detailAttachments : [],
        }
        var details = submission.submissionDetails;
        if (!details || !Array.isArray(details))
            details = [];
        details.push(newDetail);
        handleFormChange({target: {
            name: "submissionDetails", 
            value: details, 
            saveOnChange: true,
        }});
    }

    const onShowAttachments = () => {
        setShowAttachments(true);
    }

    const handleCloseAttachments = () => {
        onSaveRequest();
        setShowAttachments(false);
    }

    return (
        <Fragment>
            <Snackbar open={showAlert} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
                <Alert onClose={() => setShowAlert(false)} severity="error">
                    {alertMessage || ''}
                </Alert>
            </Snackbar>

            {submission && selectedDetail && showAttachments && 
                <SubmissionAttachmentDialog
                    className={classes.dialogStyle}
                    open={showAttachments}
                    submission={submission}
                    submissionDetail={selectedDetail}
                    dtlAttachments={dtlAttachments}
                    source="submission"
                    onDtlAttachmentsChanged={handleDtlAttachmentsChanged}
                    onRemoveAttachment={onRemoveAttachment}
                    onClose={handleCloseAttachments}
                    />
            }
            {submission && 
                <Dialog 
                    className={classes.dialogStyle}
                    fullWidth
                    maxWidth="lg"
                    open={open} 
                    onClose={onClose}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}>

                    <DialogTitle className={classes.dialogTitle}>
                        Edit Submission {submission.jobCode} - {submission.filename} {selectedDetail && `(version ${selectedDetail.version})`}
                    </DialogTitle>

                    <DialogContent dividers={false} className={classes.dialogContentStyle}>
                        {saving && 
                            <div className={classes.loading} >
                                <CircularProgress size="75px" />
                                <span>Saving...</span>
                            </div> 
                        }
                        
                        <Grid 
                            container
                            spacing={1} 
                            direction="column" 
                            justify="space-between" 
                            alignItems="stretch">
                            <Grid item xs={12}>
                                <Paper className={classes.tablePaper}>
                                    <SubmissionDetailTable 
                                        submission={submission}
                                        selectedDetail={selectedDetail}
                                        onRowSelect={onRowSelect}
                                        onDetailItemChanged={handleFormChange}/>
                                </Paper>
                                <Grid item xs={12}
                                    container spacing={1}
                                    direction="row" 
                                    justify="space-between">
                                    <Grid item xs={6} container direction="row" justify="flex-start">
                                        <Tooltip title="Add new version">
                                            <span>
                                                <Button size="small" variant="contained" onClick={onAddVersion} disabled={!submission.isRevisionAllowed}>Add Revision</Button>
                                            </span>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Paper className={classes.formPaper}>
                                    <Grid container spacing={2} direction="row" justify="flex-start" alignItems="stretch">
                                        <Grid item xs={12}>
                                            <TextField 
                                                    name="filename"
                                                    fullWidth
                                                    variant="outlined" 
                                                    size="small" 
                                                    label="Request Title" 
                                                    autoComplete="new-password"
                                                    margin="dense"
                                                    required={true}
                                                    disabled={!submission.isEditable}
                                                    value={filename}
                                                    onChange={handleFilenameChange}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                name="issuerId"
                                                fullWidth
                                                id="clientCB" 
                                                size="small"
                                                loading={!pickLists.clientList}
                                                options={pickLists.clientList}
                                                disabled={!submission.isEditable}
                                                required={true}
                                                value={issuer}
                                                selectOnFocus={true}
                                                autoSelect={true}
                                                disableClearable={true}
                                                getOptionSelected={isClientSelected}
                                                getOptionLabel={findClientLabel}
                                                renderInput={renderClientSelectInput}
                                                onChange={handleClientChange}
                                            />
                                        </Grid>
                                        {(issuer.submissionTarget == "DN" && selectedDetail) &&
                                            <DNSubmissionForm
                                                submission={submission}
                                                selectedDetail={selectedDetail}
                                                artType={artType}
                                                onArtTypeChange={handleArtTypeChange}
                                                prelaunchRecapFlag={dtlPrelaunchRecapFlag}
                                                onPrelaunchRecapFlagChange={handleDtlPrelaunchRecapFlagChange}
                                                dtlDescription={dtlDescription}
                                                onDescriptionChange={handleDtlDescriptionChange}
                                                dtlReviewerComments={selectedDetail.dtlReviewerComments}
                                            />
                                        }
                                        {(issuer.submissionTarget == "DCI" && selectedDetail) &&
                                            <DCISubmissionForm
                                                submission={submission}
                                                selectedDetail={selectedDetail}
                                                pickLists={pickLists}
                                                franchise={franchise}
                                                onFranchiseChange={handleFranchiseChange}
                                                mfrJobNum={mfrJobNum}
                                                onMfrJobNumChange={handleMfrJobNumChange}
                                                qty={qty}
                                                onQtyChange={handleQtyChange}
                                                cardType={cardType}
                                                onCardTypeChange={handleCardTypeChange}
                                                dtlDescription={dtlDescription}
                                                onDescriptionChange={handleDtlDescriptionChange}
                                            />
                                        }
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Grid container direction="row">
                            <Grid xs={9} item container spacing={1} direction="row" justify="flex-start">
                                <Grid item>
                                    <Button size="small" variant="contained" onClick={onConfirmCancelReq}>Cancel Request</Button>
                                </Grid>
                                <Grid item>
                                    <Button size="small" variant="contained" onClick={onConfirmSubmitReq} disabled={!submission.isEditable}>Submit Request</Button>
                                </Grid>
                                <Grid item>
                                    <Button size="small" variant="contained" onClick={onShowAttachments}>Attachments</Button>
                                </Grid>
                            </Grid>
                            <Grid xs={3} item container spacing={1} direction="row" justify="flex-end">
                                <Grid item>
                                    <Button size="small" variant="contained" onClick={onClose}>Close</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            }
        </Fragment>
    )
};

SubmissionFormDialog.propTypes = {
    open: PropTypes.bool,
    saving: PropTypes.bool,
    submission: PropTypes.object,
    pickLists: PropTypes.object,
    onUpdateSubmission : PropTypes.func,
    onSubmissionChanged : PropTypes.func,
    onClose: PropTypes.func,
    onCancelReq: PropTypes.func,
    onSubmitReq: PropTypes.func,
    onSaveRequest: PropTypes.func,
    onRemoveAttachment : PropTypes.func,
};

export default SubmissionFormDialog;
