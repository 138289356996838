import React, { useState } from 'react';
// import React, { useState } from 'react';
import { IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from "@material-ui/core"
import PropTypes from "prop-types";
import RateReviewIcon from '@material-ui/icons/RateReview';
import { formatDateMDY } from '../../../utils/Formatters';

const makeTableStyles = makeStyles(() => ({
    tableContainer: {
        height: 'calc(100% - 52px)',
        width: '100%',
        overflow: 'scroll',
    },
    tableHead: {
        padding: '0px 5px',
    },
    tableHeadRow: {
        verticalAlign: 'baseline',
    },
    tableHeadCell: {
        padding: '1px 5px',
        fontWeight: 'bold',
        backgroundColor: '#e9e9e9',
        borderBottom: '1px solid',
    },
    tableDetailRow: {
        padding: '1px 5px',
        verticalAlign: 'baseline',
        '&$tableDetailRowEven': {
            backgroundColor : '#bebebe',
        },
    },
    tableDetailRowEven: {
    },
    tableDetailCell: {
        padding: '1px 5px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    issuerNameCol: {
        maxWidth: '125px',
    },
    jobCodeCol: {
        maxWidth: '95px',
    },
    titleCol: {
        maxWidth: '195px',
    },
    descriptionCol: {
        maxWidth: '195px',
    },
    statusCol: {
        maxWidth: '95px',
    },
    versionCol: {
        maxWidth: '45px',
    },
    submittedCol: {
        maxWidth: '75px',
    },
    ageCol: {
        maxWidth: '60px',
        textAlign: 'right',
    },
    actionCol: {
        maxWidth: '30px',
        width: '30px',
    },
    iconButton: {
        padding: '0px',
    }
}));

const TblRow = (props) => {
    const {
        review,
        selectedItem,
        onClick,
        onShowReview,
    } = props;
    const classes = makeTableStyles();

    const clickHandler = () => {
        onClick(review);
    }

    const handleShowReview = item => {
        onShowReview(item);
    }

    return (
        <React.Fragment>
            <TableRow hover={true} onClick={clickHandler} selected={selectedItem && review && review.issuerArtId == selectedItem.issuerArtId}>
                <Tooltip title={review.issuerName || 'No Issuer selected'}>
                    <TableCell className={`${classes.tableDetailCell} ${classes.issuerNameCol}`}>
                        {review.issuerName}
                    </TableCell>
                </Tooltip>
                <Tooltip title={review.jobCode || ''}>
                    <TableCell className={`${classes.tableDetailCell} ${classes.jobCodeCol}`}>
                        {review.jobCode}
                    </TableCell>
                </Tooltip>
                <Tooltip title={review.filename? review.filename: "No filename provided"}>
                    <TableCell className={`${classes.tableDetailCell} ${classes.titleCol}`}>
                        {review.filename}
                    </TableCell>
                </Tooltip>
                <Tooltip title={review.description || ""}>
                    <TableCell className={`${classes.tableDetailCell} ${classes.descriptionCol}`}>
                        {review.description}
                    </TableCell>
                </Tooltip>
                <Tooltip title={review.statusDesc || ""}>
                    <TableCell className={`${classes.tableDetailCell} ${classes.statusCol}`}>
                        {review.statusDesc? review.statusDesc : review.status}
                    </TableCell>
                </Tooltip>
                <Tooltip title={`Version ${review.currentVersion || '0'}`}>
                    <TableCell className={`${classes.tableDetailCell} ${classes.versionCol}`}>
                        {review.currentVersion}
                    </TableCell>
                </Tooltip>
                <Tooltip title={review.uploadDate || "Not uploaded yet"}>
                    <TableCell className={`${classes.tableDetailCell} ${classes.submittedCol}`}>
                        {formatDateMDY(review.submitDate)}
                    </TableCell>
                </Tooltip>
                <Tooltip title={review.submitAge? `${review.submitAge} days`: "Not uploaded yet"}>
                    <TableCell className={`${classes.tableDetailCell} ${classes.ageCol}`}>
                        {review.submitAge}
                    </TableCell>
                </Tooltip>
                <Tooltip title="Review Submission">
                    <TableCell className={`${classes.tableDetailCell} ${classes.actionCol}`}>
                        <IconButton onClick={() => handleShowReview(review)} className={classes.iconButton}>
                            <RateReviewIcon/>
                        </IconButton>
                    </TableCell>
                </Tooltip>
            </TableRow>
        </React.Fragment>
    )
}

TblRow.propTypes = {
    review: PropTypes.object,
    selectedItem : PropTypes.object,
    onClick: PropTypes.func,
    onShowReview: PropTypes.func,
};

/**
 * TODO: Implement virtualized table as described in the material-ui docs
 * https://material-ui.com/components/tables/#virtualized-table
 * https://github.com/bvaughn/react-virtualized
 */
const ReviewTable = (props) => {
    const {
        reviewItems,
        selectedListItem,
        onSelection,
        onShowReview,
    } = props;

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const classes = makeTableStyles();

    const rowClickHandler = (review) => {
        onSelection(review);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const getReviewItemsLength = () => {
        if (reviewItems == null || !Array.isArray(reviewItems)) {
            return 0;
        }
        return reviewItems.length;
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, getReviewItemsLength() - page * rowsPerPage);

    return (
        <React.Fragment>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="Review Table" size="small">
                    <TableHead className={classes.tableHead}>
                        <TableRow className={classes.tableHeadRow}>
                            <TableCell className={`${classes.tableHeadCell} ${classes.issuerNameCol}`}>
                                Issuer Name
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.jobCodeCol}`}>
                                Job Number
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.titleCol}`}>
                                File Name
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.descriptionCol}`}>
                                Description
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.statusCol}`}>
                                Status
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.versionCol}`}>
                                Version
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.submittedCol}`}>
                                Submitted
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.ageCol}`}>
                                Age
                            </TableCell>
                            <TableCell className={`${classes.tableHeadCell} ${classes.ageCol}`}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reviewItems && 
                            reviewItems
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((review, index) => {
                            return (<TblRow index={index} 
                                review={review} 
                                selectedItem={selectedListItem}
                                key={`${review.issuerArtId}.${index}`} 
                                onClick={rowClickHandler}
                                onShowReview={onShowReview}/>)
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: (33 * emptyRows) }}>
                            <TableCell colSpan={8} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination rowsPerPageOptions={[10,25,50,100]}
                component="div"
                count={getReviewItemsLength()}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}/>
        </React.Fragment>
    )
};

ReviewTable.propTypes = {
    reviewItems: PropTypes.array,
    selectedListItem : PropTypes.object,
    onSelection: PropTypes.func,
    onShowReview: PropTypes.func,
};

export default ReviewTable;