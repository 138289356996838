import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
// import Grid from '@material-ui/core/Grid';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { getRememberMe, setRememberMe, getLocalUsername, setLocalUsername, clearLocalUsername } from '../utils/storageAccess';
import PropTypes from "prop-types";
import authenticationService from '../services/authenticationService';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const useFormInput = (initialValue, changehandler) => {
    const [value, setValue] = useState(initialValue);
 
    const handleChange = e => {
        setValue(e.target.value);
        if (changehandler)
            changehandler(value);
    }
    return {
        value,
        onChange: handleChange
    }
}

const useFormCheckbox = (initialValue) => {
    const [checked, setChecked] = useState(initialValue);
 
    const handleChange = e => {
        setChecked(e.target.checked);
    }
    return {
        checked,
        onChange: handleChange
    }
}

const SignIn = (props) => {
    const username = useFormInput((getRememberMe() && getLocalUsername()) || '');
    const password = useFormInput('');
    const rememberme = useFormCheckbox(getRememberMe());
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
  
    const history = useHistory();
    const classes = useStyles();

    // handle button click of login form
    const handleLogin = () => {
        if (!username.value || !password.value) {
            setError("A username and password must be provided");
            return;
        }
        setError(null);
        setLoading(true);
    
        authenticationService.login(username.value, password.value)
        .then(response => {
            if (response) {
            setLoading(false);
            setRememberMe(rememberme.checked);
            if (rememberme.checked)
                setLocalUsername(username.value);
            else  
                clearLocalUsername();
            props.onLoginComplete(response);
            history.push("/");
            }
        })
        .catch(error => {
            setLoading(false);
            console.log(`login error: ${error}`);
            setError("Login failed for provided credentials.");
        });
    }

    // const handleResetPassword = () => {
    //     console.log("request to reset password");
    // }

    return (
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
            <Avatar className={classes.avatar}>
            {/* <LockOutlinedIcon /> */}
            </Avatar>
            <Typography component="h1" variant="h5">
            Log in
            </Typography>
            <form className={classes.form} noValidate>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="new-password"
                autoFocus
                {...username}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                {...password}
            />
            {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
            <FormControlLabel
                control={<Checkbox value="remember" color="primary" {...rememberme} />}
                label="Remember me"
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleLogin}
                disabled={loading}
            >
                {loading? "Loading..." : "Sign In"}
            </Button>
            {/* <Grid container>
                <Grid item xs>
                <Link variant="body2" onClick={handleResetPassword}>
                    Forgot password?
                </Link>
                </Grid>
            </Grid> */}
            </form>
        </div>
        </Container>
    );
}

SignIn.propTypes = {
  onLoginComplete: PropTypes.func.isRequired,
};

export default SignIn
