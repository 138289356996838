import React, { Fragment, useEffect, useState } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import SubmissionFilter from './components/SubmissionFilter';
import SubmissionTable from './components/SubmissionTable';
import SubmissionFormDialog from './components/SubmissionFormDialog';
import SubmissionService from './services/SubmissionService';
import TranslateService from '../../services/TranslateService';
const submissionService = new SubmissionService();
const translateService = new TranslateService();

const makeSubmissionStyles = makeStyles(() => ({
    formDialogStyle: {
        width: '75%',
        height : '75%',
    },
    headerStyle: {
        flex: '0 1 auto',
    },
    bodyStyle: {
        flex: '1 1 auto',
        overflow: 'auto',
    },
    loading: {
        top: '40%',
        left: '40%',
        position: 'fixed',
        display: 'flex',
        flexFlow: 'column',
        zIndex: '99',
        textAlign: 'center',
    },
  }));

const getPickLists = async (setPickLists) => {
    Promise.all([
        submissionService.getSubmissionClients(),
        translateService.getFranchiseList(),
    ])
    .then(([
        clients,
        franchises,
    ]) => {
        setPickLists({
            clientList: clients,
            franchiseList : franchises,
        });
    });
}

const saveSubmission = async submission => {
    if (!submission)
        return null;

    if (!submission.isDirty)
        return submission;

    return submissionService.saveSubmission(submission)
    .then(response => {
        return response;
    })
    .catch(error => {
        console.log(`An error occurred saving the submission: ${error}`);
        return null;
    })
}

const deleteSubmissionDetailAttachment = async attachment => {
    if (!attachment)
        return null;
    
    if (attachment.issuerArtDtlAttachmentId <= 0)
        return true;
    
    return submissionService.removeAttachmentById(attachment.issuerArtDtlAttachmentId)
    .then(() => true)
    .catch(error => {
        console.log(`An error occurred removing the attachment: ${error}`);
        Promise.reject(`An error occurred removing the attachment: ${error}`);
    })
}

const SubmissionModule = () => {
    const [pickLists, setPickLists] = useState({});

    const [filter, setFilter] = useState(null);
    const [submissionList, setSubmissionList] = useState([]);
    const [selectedListItem, setSelectedListItem] = useState(null);
    const [submissionToEdit, setSubmissionToEdit] = useState(null);
    const [showForm, setShowForm] = useState(false);

    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const classes = makeSubmissionStyles();

    useEffect(() => {
        getPickLists(setPickLists);
    }, []);

    // on filter change
    useEffect(() => {
        if (!filter)
            return;

        setSubmissionList([]);
        setSelectedListItem(null);
        setSubmissionToEdit(null);

        setLoading(true);
        submissionService.getSubmissionList(filter)
        .then(result => {
            if (!Array.isArray(result)) {
                setSubmissionList([]);
                setLoading(false);
                return;
            }
            setSubmissionList(result);
            setLoading(false);
        });
    }, [filter]);

    // When a submission is selected in the list...
    useEffect(() => {
        setSubmissionToEdit(null);
        editSubmission();
    }, [selectedListItem]);

    const updateSubmissionInList = submission => {
        var list = submissionList;
        var index = list.findIndex(item => item.issuerArtId == submission.issuerArtId);
        if (!index == -1) {
            index = list.find(item => item.issuerArtId <= 0);
        }
        if (index >= 0) {
            list[index] = submission;
        } else {
            list.push(submission);
        }

        setSubmissionList(list);
    }

    // const handleUpdateSubmission = (update, doSave) => {
    //     var updatedSubmission = Object.assign(submissionToEdit, update);
    //     setSubmissionToEdit(updatedSubmission);
    //     if (doSave) 
    //         handleSaveRequest();
    // }

    const editSubmission = () => {
        setLoading(true);
        getSubmissionDetails();
    }

    const selectionHandler = (item) => {
        if (!item || !item.issuerArtId) {
            console.log("Invalid submission item selected");
            return;
        }
        if (selectedListItem && item.issuerArtId == selectedListItem.issuerArtId)
            editSubmission();
        else
            setSelectedListItem(item);
    }

    const getSubmissionDetails = async () => {
        if (!selectedListItem || !selectedListItem.issuerArtId)
            return;
        
        submissionService.getSubmissionDetails(selectedListItem.issuerArtId)
        .then(response => {
            setSubmissionToEdit(response)
            setLoading(false);
            setShowForm(true);
        })
        .catch(error => {
            Promise.reject(`An error occurred retrieving the submission details: ${error}`);
            setLoading(false);
        });
    }

    const handleAddRequest = () => {
        setSaving(true);
        var newSubmission = {issuerArtId : -1};
        if (pickLists && pickLists.clientList && Array.isArray(pickLists.clientList) && pickLists.clientList.length == 1)
            newSubmission.issuerId = pickLists.clientList[0].issuerId;
        submissionService.addSubmission(newSubmission)
        .then(response => {
            var listItem = {
                issuerArtId : response.issuerArtId,
                issuerName : response.issuerName,
                submittedByPersonName : response.submittedByPersonName,
                filename : response.filename,
                keywords : response.keywords,
                description : response.description,
                status : response.statusDesc,
                currentVersion : response.currentVersion,
                uploadDate : response.uploadDate,
                jobCode : response.jobCode,
                submissionTarget : response.submissionTarget,
                dtlStatus : response.dtlStatusDesc,
            }
            
            updateSubmissionInList(listItem);
            setSaving(false);
            setSelectedListItem(listItem);
        })
        .catch(() => {
            setSaving(false);
        })
    }

    const handleSubmissionChanged = changedSubmission => {
        setSubmissionToEdit(changedSubmission);
    }

    const handleCancelReq = () => {
        setSaving(true);
        submissionService.cancelSubmission(submissionToEdit)
        .then(response => {
            var listItem = {
                issuerArtId : response.issuerArtId,
                issuerName : response.issuerName,
                submittedByPersonName : response.submittedByPersonName,
                filename : response.filename,
                keywords : response.keywords,
                description : response.description,
                status : response.statusDesc,
                currentVersion : response.currentVersion,
                uploadDate : response.uploadDate,
                jobCode : response.jobCode,
                submissionTarget : response.submissionTarget,
                dtlStatus : response.dtlStatusDesc,
            }
            
            updateSubmissionInList(listItem);
            setSaving(false);
            setShowForm(false);
            setSubmissionToEdit(null);
        })
        .catch(() => {
            setSaving(false);
        })
    }

    // TODO
    const handleSubmitReq = submissionDtl => {
        setSaving(true);
        saveSubmission(submissionToEdit)
        .then(() => {
            setSaving(false);
            return submissionService.submitSubmission(submissionDtl)
        })
        .then(() => {
            setShowForm(false);
            setLoading(true);
            return submissionService.getSubmissionList(filter)
        })
        .then(result => {
            if (!Array.isArray(result)) {
                setSubmissionList([]);
                setLoading(false);
                return;
            }
            setSubmissionList(result);
            setLoading(false);
        })
        .catch(() => {
            setSaving(false);
        })
    }

    const handleSaveRequest = () => {
        saveSubmission(submissionToEdit)
        .then(response => {
            if (!response) {
                return Promise.reject(`Empty response from saving the submission`);
            }
            setSubmissionToEdit(response);
            var listItem = {
                issuerArtId : response.issuerArtId,
                issuerName : response.issuerName,
                submittedByPersonName : response.submittedByPersonName,
                filename : response.filename,
                keywords : response.keywords,
                description : response.description,
                status : response.statusDesc,
                currentVersion : response.currentVersion,
                uploadDate : response.uploadDate,
                jobCode : response.jobCode,
                submissionTarget : response.submissionTarget,
                dtlStatus : response.dtlStatusDesc,
            }
            
            updateSubmissionInList(listItem);
        })
        .then(() => {
            setSaving(false);
        })
        .catch(error => {
            console.log(`An error occurred somewhere in teh save process: ${error}`);
            return false;
        })
    }

    const onRemoveAttachment = attachment => {
        deleteSubmissionDetailAttachment(attachment)
        .then(deleteSuccess => {
            if (deleteSuccess) {
                editSubmission();
            }
        })
    }

    const handleClose = () => {
        setSaving(true);
        saveSubmission(submissionToEdit)
        .then(response => {
            var listItem = {
                issuerArtId : response.issuerArtId,
                issuerName : response.issuerName,
                submittedByPersonName : response.submittedByPersonName,
                filename : response.filename,
                keywords : response.keywords,
                description : response.description,
                status : response.statusDesc,
                currentVersion : response.currentVersion,
                uploadDate : response.uploadDate,
                jobCode : response.jobCode,
                submissionTarget : response.submissionTarget,
                dtlStatus : response.dtlStatusDesc,
            }
            
            updateSubmissionInList(listItem);
            setSaving(false);
            setShowForm(false);
            setSubmissionToEdit(null);
        })
        .catch(() => {
            setSaving(false);
        })
    }

    return (
        <Fragment>
            {loading && 
                <div className={classes.loading} >
                    <CircularProgress size="75px" />
                    <span>Loading...</span>
                </div> 
            }
            
            {submissionToEdit && showForm && 
            <SubmissionFormDialog 
                className={classes.formDialogStyle}
                open={showForm} 
                saving={saving}
                submission={submissionToEdit}
                pickLists={pickLists}
                onSubmissionChanged={handleSubmissionChanged}
                // onUpdateSubmission={handleUpdateSubmission}
                onClose={handleClose}
                onCancelReq={handleCancelReq}
                onSubmitReq={handleSubmitReq}
                onSaveRequest={handleSaveRequest}
                onRemoveAttachment={onRemoveAttachment}
                />}

            <SubmissionFilter 
                className={classes.headerStyle} 
                onFilterChange={setFilter}
                onAddRequest={handleAddRequest}/>
            <SubmissionTable 
                className={classes.bodyStyle} 
                submissionItems={submissionList}
                selectedListItem={selectedListItem}
                onSelection={selectionHandler}
                loading={loading}/>
        </Fragment>
    );
}

export default SubmissionModule;
