import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';

const makeFilterStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row',
    },
    filterLegend: {
        flex: '0 1 auto',
        paddingRight: '10px',
    },
    filterOption: {
        flex: '0 1 auto',
    },
}));

/**
 * Changes to the filter options have to be mirrored in the apiReviewService.cfc.
 */
const FilterOptions = {
    ATTN: {value: "REQ_ATTN", label: "Requiring Attention",},
    IP: {value: "IP", label: "In Process",},
    PEND: {value: "PEND_SAMPLE", label: "Pending Samples",},
    RECAP: {value: "QUARTERLY_RECAP", label: "Quarterly Recap",},
    ALL: {value: "ALL", label: "All",},
}; 
    
const ReviewFilter = ({
    onFilterChange,
}) => {
    const [currentFilter, setCurrentFilter] = useState(FilterOptions.ATTN.value);

    const classes = makeFilterStyles();

    const handleFilterChange = (event) => {
        setCurrentFilter(event.target.value);
    }

    useEffect(() => {
        onFilterChange(currentFilter);
    }, [currentFilter]);

    return (
        <FormControl className={classes.container} size="small">
            <FormLabel className={classes.filterLegend} >Show Which Items:</FormLabel>
            <RadioGroup row name="filterGroup" value={currentFilter} onChange={handleFilterChange}>
                <FormControlLabel 
                    className={classes.filterOption} 
                    value={FilterOptions.ATTN.value}
                    control={<Radio size="small" />} 
                    label={FilterOptions.ATTN.label}/>
                <FormControlLabel 
                    className={classes.filterOption} 
                    value={FilterOptions.IP.value}
                    control={<Radio size="small" />} 
                    label={FilterOptions.IP.label}/>
                <FormControlLabel 
                    className={classes.filterOption} 
                    value={FilterOptions.PEND.value}
                    control={<Radio size="small" />} 
                    label={FilterOptions.PEND.label}/>
                <FormControlLabel 
                    className={classes.filterOption} 
                    value={FilterOptions.RECAP.value}
                    control={<Radio size="small" />} 
                    label={FilterOptions.RECAP.label}/>
                <FormControlLabel 
                    className={classes.filterOption} 
                    value={FilterOptions.ALL.value}
                    control={<Radio size="small" />} 
                    label={FilterOptions.ALL.label}/>
            </RadioGroup>
        </FormControl>
    );
};

ReviewFilter.propTypes = {
    onFilterChange: PropTypes.func.isRequired,
};

export default ReviewFilter;

