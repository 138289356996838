import React, { Fragment, useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Input, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core';
import PropTypes from "prop-types";
import FileApi from '../../../services/api/FileApi';
import AttachmentTable from '../../../components/AttachmentTable';
import { openBase64NewTab } from '../../../utils/FileUtils';
import { useConfirm } from "material-ui-confirm";
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const fileApi = new FileApi();

const makeDialogStyles = makeStyles(() => ({
    tablePaper: {
        minHeight : '125px',
    },
    tableStyle : {
        height : '100%',
    },
    dialogTitle: {
        padding: '5px 5px',
        backgroundColor: '#cfcfcf',
    },
    dialogActions: {
        padding: '5px 5px',
        backgroundColor: '#cfcfcf',
        justifyContent: 'flex-end',
    },
    toggleButton: {
        height : 'fit-content',
    },
    iconButton: {
        padding : '4px',
    },
    dialogContentStyle: {
        height: 'auto',
        width: 'auto',
        padding: '8px 8px',
    },
    formPaper: {
        padding: ' 5px',
    },
    imageGridContainer : {
        minHeight : '125px',
    },
    horizontalSpacer: {
        width: '100%',
    },
    fileInputStyle : {
        height : '150px',
    },
    iframeContainer : {
        minHeight : '250px',
    },
    iframeElement : {
        height : '100%',
        width: '100%',
    },
    imageElement : {
        width: '100%',
    },
    textElement : {
        height : '100%',
        textAlign: 'center',
    },
}));

const createFileResourceDto = attachmentItem => {
    if (!attachmentItem || 
        !attachmentItem.filesize || 
        !attachmentItem.filename || 
        !attachmentItem.localFilename || 
        !attachmentItem.localPath)
        return null;

    const fileResourceDto = {
        originalFilename : attachmentItem.filename,
        localFilename : attachmentItem.localFilename,
        localFilepath : attachmentItem.localPath,
        filesize : attachmentItem.filesize,
        mimeType : "",
        encodedFile : "",
    }
    return fileResourceDto;
}

const ReviewAttachmentDialog = (props) => {
    const {
        open,
        review,
        reviewDetail,
        dtlAttachments,
        source,
        onDtlAttachmentsChanged,
        onRemoveAttachment,
        onClose,
    } = props;

    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [largeContent, setLargeContent] = useState(false);
    const [attachmentContent, setAttachmentContent] = useState(null);
    const [attachmentMimeType, setAttachmentMimeType] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [newAttachmentId, setNewAttachmentId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [attachmentType, setAttachmentType] = useState('SUBMISSION');
    const [isAddDisabled, setIsAddDisabled] = useState(true);
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
    const [filteredAttachmentsList, setFilteredAttachmentsList] = useState(null);

    const classes = makeDialogStyles();
    const confirm = useConfirm();

    useEffect(() => {
        setFilteredAttachmentsList(dtlAttachments.filter(attachmentListFilter))
    }, [dtlAttachments, attachmentType]);

    useEffect(() => {
        if (filteredAttachmentsList && Array.isArray(filteredAttachmentsList) && filteredAttachmentsList.length > 0) {
            var att;
            if (selectedAttachment != null) {
                att = filteredAttachmentsList.find(listItem => listItem.issuerArtDtlAttachmentId == selectedAttachment.issuerArtDtlAttachmentId);
            }
            if (!att) {
                setSelectedAttachment(filteredAttachmentsList[filteredAttachmentsList.length-1]);
                setNewAttachmentId((filteredAttachmentsList.length + 1) * -1);
            } else {
                setSelectedAttachment(att);
            }
        } else {
            setSelectedAttachment(null);
        }
    }, [filteredAttachmentsList]);

    useEffect(() => {
        setAttachmentContent(null);
        if (selectedAttachment && !!selectedAttachment.localFilename)
            getAttachmentContent(false);
        else
            getAttachmentContent(null);
    }, [selectedAttachment]);

    useEffect(() => {
        if (source == 'review') {
            if (attachmentType == 'SAMPLE') {
                setIsAddDisabled(false);
                return;
            }
        }
        if (source == 'submission') {
            if (attachmentType == 'SUBMISSION' && reviewDetail) {
                setIsAddDisabled(!reviewDetail.isEditable);
                return;
            }
        }
        setIsAddDisabled(true);
    }, [source, attachmentType, reviewDetail]);

    useEffect(() => {
        if (source == 'review') {
            if (attachmentType == 'SAMPLE') {
                setIsDeleteDisabled(false);
                return;
            }
        }
        if (source == 'submission') {
            if (attachmentType == 'SUBMISSION' && reviewDetail) {
                setIsDeleteDisabled(!reviewDetail.isDeletable);
                return;
            }
        }
        setIsDeleteDisabled(true);
    }, [source, attachmentType, reviewDetail]);

    const attachmentListFilter = item => {
        return item && item.attachmentType && item.attachmentType == attachmentType;
    }

    const onRowSelect = selectedRow => {
        if (!selectedRow)
            return;

        if (!selectedAttachment || selectedAttachment.issuerArtDtlAttachmentId != selectedRow.issuerArtDtlAttachmentId)
            setSelectedAttachment(selectedRow);
    }

    const handleToggleAttachmentType = () => {
        if (!attachmentType || attachmentType == 'SAMPLE') {
            setAttachmentType('SUBMISSION');
        } else {
            setAttachmentType('SAMPLE');
        }
    }

    const handleAttachmentChange = attachment => {
        var attachments = reviewDetail.detailAttachments;
        var attachmentIndex = attachments.findIndex(att => att.issuerArtDtlAttachmentId == attachment.issuerArtDtlAttachmentId);
        attachments[attachmentIndex] = attachment;
        onDtlAttachmentsChanged(attachments);
    }

    const handleNewAttachment = attachment => {
        var attachments = reviewDetail.detailAttachments;
        if (!attachments || !Array.isArray(attachments))
            attachments = [];
        attachments.push(attachment);
        filteredAttachmentsList.push(attachment);
        onDtlAttachmentsChanged(attachments);
        setSelectedAttachment(attachment);
    }

    const getAttachmentContent = fetchLargeFiles => {
        if (!selectedAttachment)
            return;

        if (selectedAttachment.filesize >= 10000000 && !fetchLargeFiles) {
            setLargeContent(true);
            return;
        } else {
            setLargeContent(false);
        }

        setLoading(true);

        fileApi.getFileContents(createFileResourceDto(selectedAttachment))
        .then(response => {
            setAttachmentContent(response.encodedFile);
            setAttachmentMimeType(response.mimeType);
            setLoading(false);
        })
        .catch(error => {
            console.log(`An error occurred retrieving the file contents for display: ${error}`);
            setLoading(false);
        });
    }

    const onCloseClicked = () => {
        onClose();
    }

    const onAddClicked = () => {
        setUploading(true);
    }
    const onAddCancelled = () => {
        setUploading(false);
    }

    const handleFileChanged = event => {
      event.preventDefault();
  
      if (event.target.files.length > 1) {
        alert("You may only upload one file at a time");
        return;
      }
  
      setSelectedFile(event.target.files[0]);
    }  

    const handleUploadBtnClicked = event => {
        // upload file
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', selectedFile);
        fileApi.uploadFile(formData)
        .then(response => {
            var attachmentItem = {
                issuerArtDtlAttachmentId : newAttachmentId,
                issuerArtDtlId : reviewDetail.issuerArtDtlId,
                filename : response.originalFilename,
                comments : '',
                localFilename : response.localFilename,
                localPath : response.localFilepath,
                filesize : response.filesize,
                attachmentType : attachmentType,
                status : '',
                statusDescription: '',
                isDeletable : true,
                isEditable : true,
            };
            setUploading(false);
            setNewAttachmentId(newAttachmentId-1);
            handleNewAttachment(attachmentItem);
            return response;
        })
        .catch(error => {
            console.log(`An error occurred while uploading the file: ${error}`);
            setUploading(false);
        })
    }

    const onRemoveClicked = () => {
        confirm({ description: `This will permanently delete ${selectedAttachment.filename}.` })
        .then(() => onRemoveAttachment(selectedAttachment))
        .catch(() => console.log("Deletion cancelled."));
    }

    const onDownloadClicked = () => {
        if (!selectedAttachment) {
            console.log("No attachment selected to download.");
            return;
        }

        const fileDto = createFileResourceDto(selectedAttachment);
        if (!fileDto) {
            console.log("Could not create file resource request");
            return;
        }

        fileApi.downloadFile(fileDto)
        .then(response => {
            let blob = new Blob([response], {type: attachmentMimeType});
    
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = fileDto.originalFilename;
            a.click();
        })
    }

    const onPopoutClicked = () => {
        if (!attachmentContent)
            return;

        openBase64NewTab(attachmentContent, selectedAttachment.filename, attachmentMimeType);
    }

    return (
        <Fragment>
            {review && reviewDetail && 
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={open} 
                    onClose={onClose}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}>

                    <DialogTitle className={classes.dialogTitle}>
                        {attachmentType == 'SAMPLE'? "Samples" : "Attachments"} for {review.jobCode} - {review.filename} {reviewDetail && `(version ${reviewDetail.version})`}
                    </DialogTitle>

                    <DialogContent dividers={false} className={classes.dialogContentStyle}>
                        <Grid 
                            container
                            spacing={1} 
                            direction="row" 
                            justify="space-between">
                            <Grid item xs={12} container spacing={1} direction="row">
                                <Grid item xs={12}>
                                    <Paper className={classes.tablePaper}>
                                        <AttachmentTable className={classes.tableStyle}
                                            attachmentsList={filteredAttachmentsList}
                                            selectedAttachment={selectedAttachment}
                                            filterFunc={attachmentListFilter}
                                            onRowSelect={onRowSelect}
                                            onAttachmentChanged={handleAttachmentChange}
                                            onRemoveAttachment={onRemoveAttachment}/>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}
                                    container spacing={1}
                                    direction="row" 
                                    justify="space-between">
                                    <Grid item xs={4} container direction="row" justify="flex-start">
                                        {reviewDetail &&
                                        <Tooltip title="Add new file">
                                            <span>
                                                <IconButton onClick={onAddClicked} disabled={isAddDisabled} className={classes.iconButton}>
                                                    <AddBoxIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>}
                                        {selectedAttachment &&
                                        <Tooltip title="Remove selected file">
                                            <span>
                                                <IconButton onClick={onRemoveClicked} disabled={isDeleteDisabled} className={classes.iconButton}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>}
                                    </Grid>
                                    <Grid item xs={4} container direction="row" justify="center">
                                        {attachmentType && 
                                            <Button onClick={handleToggleAttachmentType} size="small" variant="contained" className={classes.toggleButton}>
                                                {attachmentType == 'SAMPLE' && "Show Submissions"}
                                                {attachmentType == 'SUBMISSION' && "Show Samples"}
                                            </Button>
                                        }
                                    </Grid>
                                    <Grid item xs={4} container direction="row" justify="flex-end">
                                        <Tooltip title="Download file">
                                            <IconButton onClick={onDownloadClicked} className={classes.iconButton}>
                                                <SaveAltIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Show in new window">
                                            <IconButton onClick={onPopoutClicked} className={classes.iconButton}>
                                                <OpenInNewIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.imageGridContainer}>
                                <Paper className={classes.formPaper}>
                                    <Grid container className={classes.iframeContainer} direction="row" justify="center" alignItems="stretch">
                                        {loading && 
                                            <Grid item container direction="column" justify="center" alignItems="center">
                                                <CircularProgress size="75px" />
                                                <span>Loading...</span>
                                            </Grid>
                                        }
                                        {uploading && 
                                            <Grid item container direction="column" justify="center" alignItems="center">
                                                <Input type="file" name="file" onChange={handleFileChanged}/>
                                                <Grid item container direction="row" justify="center" spacing={2}>
                                                    <Grid item>
                                                        <Button size="small" variant="contained" disabled={!selectedFile} onClick={handleUploadBtnClicked}>Upload Selected File</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button size="small" variant="contained" onClick={onAddCancelled}>Cancel</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        {!uploading && largeContent && <div>
                                            <span>This file is very large and may take awhile to display</span>
                                            <Button size="small" variant="contained" onClick={() => {getAttachmentContent(true)}}>Show file anyway</Button>
                                        </div>}
                                        {!uploading && attachmentContent && 
                                            <Grid item container direction="column" justify="center" alignItems="stretch">
                                                {attachmentMimeType && attachmentMimeType == "application/pdf" && <iframe className={classes.iframeElement} src={"data:" + attachmentMimeType + ";base64, " + attachmentContent}/>}
                                                {attachmentMimeType && attachmentMimeType.startsWith("image") && <img className={classes.imageElement} src={"data:" + attachmentMimeType + ";base64, " + attachmentContent}/>}
                                                {(!attachmentMimeType || 
                                                    (attachmentMimeType && attachmentMimeType != "application/pdf" && !attachmentMimeType.startsWith("image")))
                                                    && <Typography className={classes.textElement}>This attachment cannot be displayed.</Typography>}
                                            </Grid>
                                        }
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button size="small" variant="contained" onClick={onCloseClicked}>Close</Button>
                    </DialogActions>
                </Dialog>
            }
        </Fragment>
    )
};

ReviewAttachmentDialog.propTypes = {
    open: PropTypes.bool,
    review: PropTypes.object,
    reviewDetail : PropTypes.object,
    source : PropTypes.string,
    dtlAttachments : PropTypes.array,
    onDtlAttachmentsChanged : PropTypes.func,
    onClose: PropTypes.func,
    onRemoveAttachment : PropTypes.func,
};

export default ReviewAttachmentDialog;
